<template>
  <v-container fluid>
    <v-row class="page-wrapper">
      <v-col
        cols="12"
        md="6"
        lg="7"
        class="banner-wrapper loin-new__container p-0"
      >
        <div
          class="d-flex flex-column justify-content-between"
          style="height: 65%"
        >
          <img
            class="img-fluid rounded-lg mt-7 ml-10 iq-purple-logo"
            src="/img/iqplatform.png"
            alt="logo"
          />
          <div class="d-flex justify-content-center">
            <div class="w-75">
              <div class="d-flex align-items-center mb-3">
                <h1 class="onboarding-hi-text">Hi</h1>
                <img
                  src="/img/waving-hand.png"
                  alt="powering-ecommerce"
                  class="pr-2 w-55px h-55px ml-5"
                />
              </div>
              <h1 class="onboarding-message">
                Your
                <span style="color: rgba(126, 4, 183, 0.8)">all in one</span>
                platform is almost there....
              </h1>
              <div class="d-flex justify-content-center align-items-center">
                <img
                  src="/img/undraw_internet_on_the_go_re_vben.svg"
                  class="p-2 img-fluid onboarding-img-height"
                />
              </div>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="5"
        class="overflow-auto d-flex flex-column justify-content-between poppins container-wrapper-right h-100"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-grow-1 mt-lg-0 signin-container-wrapper"
        >
          <div class="signin-container w-325px w-lg-400px w-md-50 px-3 px-sm-0">
            <div class="font-weight-boldest mb-16">
              <h1 class="font-weight-boldest title mb-3 mt-15 mt-lg-0">
                Sign up
              </h1>
              <p class="subtitle">Run, Manage & Scale your eCommerce</p>
            </div>
            <b-form @submit="signinUser">
              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 ml-3">
                      <span class="svg-icon">
                        <v-icon size="28">mdi-account-outline</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    placeholder="Name"
                    required
                    v-model="form.name"
                    name="name"
                    type="text"
                    id="name"
                    class="email-input"
                    ref="name"
                    :state="isNameValid"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 ml-3">
                      <span class="svg-icon">
                        <v-icon size="28">mdi-office</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    placeholder="Company"
                    required
                    v-model="form.company"
                    name="company"
                    type="text"
                    id="company"
                    class="email-input"
                    ref="company"
                    :state="isCompanyValid"
                  ></b-form-input>
                </b-input-group>
              </div>

              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 ml-3">
                      <span class="svg-icon">
                        <v-icon size="28">mdi-email-outline</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    placeholder="Email"
                    required
                    v-model="form.email"
                    name="email"
                    type="email"
                    id="email"
                    class="email-input"
                    ref="email"
                    :state="isEmailValid"
                  ></b-form-input>
                </b-input-group>
              </div>

              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 ml-3">
                      <span class="svg-icon">
                        <v-icon size="28">mdi-phone-outline</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    placeholder="Phone number"
                    required
                    v-model="form.phone_number"
                    name="phone_number"
                    type="tel"
                    id="phone_number"
                    class="email-input"
                    ref="phone_number"
                    :state="isPhoneNumberValid"
                  ></b-form-input>
                </b-input-group>
                <div class="text-white font-size-lg mt-6 link-white">
                  Already have an account?

                  <router-link
                    class="link-white text-decoration-underline"
                    :to="{ path: '/login', query: $route.query }"
                  >
                    Sign in</router-link
                  >
                </div>
              </div>

              <div
                class="d-flex submit-button-container justify-content-center mt-10 p-1"
              >
                <button
                  type="submit"
                  ref="submit"
                  class="submit-button m-1 font-weight-bolder"
                  :class="[
                    !isSubmitButtonClickable ? 'disabled' : '',
                    loading ? ' loading' : '',
                    isSubmitButtonClickable && !loading ? '' : '',
                  ]"
                >
                  <template v-if="loading">
                    Signing up...
                    <v-progress-circular
                      :size="20"
                      width="3"
                      color="primary"
                      indeterminate
                      class="ml-2 mb-1"
                    ></v-progress-circular>
                  </template>
                  <template v-else>SIGN UP</template>
                </button>
              </div>
            </b-form>
          </div>
        </div>
        <div
          class="d-flex justify-content-center text-white mb-3 mt-8 ml-3 ml-md-0"
        >
          <div>
            <img
              src="/img/iqfulfillment-white-foreground.png"
              alt="logo"
              class="img-fluid w-125px mr-2 mb-2"
            />
            <span class="font-size-h6"
              >© {{ currentYear }} - All rights reserved.</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  name: "OnboardingRegister",
  data: () => ({
    form: {
      name: "",
      company: "",
      email: "",
      phone_number: "",
    },
    loading: false,
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    isSubmitButtonClickable: function() {
      return (
        this.isEmailValid &&
        this.isNameValid &&
        this.isCompanyValid &&
        this.isPhoneNumberValid
      );
    },
    isEmailValid: function() {
      const reg = RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      );
      return reg.test(this.form.email);
    },

    isNameValid: function() {
      return this.form.name.length !== 0;
    },
    isCompanyValid: function() {
      return this.form.company.length !== 0;
    },
    isPhoneNumberValid: function() {
      const reg = RegExp(
        // eslint-disable-next-line
        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
      );
      return reg.test(this.form.phone_number);
      // return this.form.phone_number.length !== 0;
    },
  },
  // mounted() {
  //   setTimeout(() => {
  //     Swal.fire({
  //       // title: "Registered",
  //       // text: data.message,
  //       title:
  //         "Registration Successful! You will receive an email shortly to activate your account and set up your password.",
  //       icon: "success",
  //       showCancelButton: false,
  //       showConfirmButton: true,
  //       confirmButtonColor: "#7e04b7",
  //       confirmButtonText: "Ok",
  //     });
  //
  //     setTimeout(() => {
  //       this.$router.push({
  //         path: "/login",
  //       });
  //     }, 200);
  //   }, 3000);
  // },
  methods: {
    signinUser(event) {
      event.preventDefault();
      if (this.isSubmitButtonClickable && !this.loading) {
        // let email = this.form.email;
        // let name = this.form.name;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        this.loading = true;

        ApiService.post("/auth/register", { ...this.form })
          // go to which page after successfully login
          .then(({ data }) => {
            if (data.success === true) {
              Swal.fire({
                // title: "Registered",
                // text: data.message,
                title:
                  "Registration Successful! You will receive an email shortly to activate your account and set up your password.",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: "#7e04b7",
                confirmButtonText: "OK",
              });
              // .then((result) => {
              // if (result.isConfirmed) {
              setTimeout(() => {
                this.$router.push({
                  path: "/login",
                });
              }, 200);
              // }
              // });
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 2000);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/pages/login/login_new.scss";
</style>
